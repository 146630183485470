// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://ows-api.myplgs.com',
  // api_url: 'http://localhost:3000',

  // **********----------> Recaptcha <----------********** //
  recaptcha: {
    siteKey: '6Lfyp84qAAAAACT5uX9JQ9ySa-hZ-C_ghFl8BXQL'
  },

  // **********----------> ABA Payway <----------********** //
  ABA_PAYWAY: 'https://checkout.payway.com.kh/plugins/checkout2-0.js',

  // **********----------> Telegram Bot Info <----------********** //
  // telegram_bot_name: 'cheu123bot',
  // telegram_bot_id: '7933066916',
  telegram_bot_name: 'OWS_DEV_BOT',
  telegram_bot_id: '7596610366',

  // **********----------> Firebase <----------********** //
  firebaseConfig: {
    apiKey: "AIzaSyDOrPhoBYThgP-WOApRfc4u3Ru1rjZgyho",
    authDomain: "ows-ppca.firebaseapp.com",
    projectId: "ows-ppca",
    storageBucket: "ows-ppca.firebasestorage.app",
    messagingSenderId: "467384186418",
    appId: "1:467384186418:web:ff7309b33369caa8b52e2b",
    measurementId: "G-EL0BF0ZKN7"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
